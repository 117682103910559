.heroSection {
  background-image: url("../../../assets/images/home/heroBack.webp");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 78vh;
  position: relative;
  padding-top: 30px;
  margin-top: -60px;
  z-index: -1;

  .container {
    .inner {
      max-width: 969px;
      margin: 0 auto;
      margin-top: 100px !important;
      gap: 23px;
      h1 {
        line-height: 117.7%;
        text-transform: capitalize;
        z-index: 5;
        color: #090b1e;
        // font-size: 48px;
      }
      h6 {
        line-height: 158.7%; /* 31.74px */
        letter-spacing: -0.6px;
      }
      .heroBtn {
        margin-top: 33px;
      }
      .macbook {
        margin-top: 64px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .heroSection {
    height: 100vh;
    margin-top: -94px;

    .container {
      .inner {
        // margin-top: 30px !important;
        // padding-top: 40px;
        h1 {
          font-size: 48px !important;
        }
        h6 {
          font-size: 19px;
        }
        .macbook {
          width: 100%;
          height: 100%;
          margin-top: 50px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .heroSection {
    .container {
      .inner {
        h1 {
          font-size: 35px !important;
        }
        h6 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .heroSection {
    height: auto;
    margin-top: -60px;
    padding-top: 30px;
    .container {
      .inner {
        margin-top: 25px !important;
        padding-top: 30px;
        h1 {
          font-size: 28px !important;
        }
        h6 {
          font-size: 18px;
        }
        .macbook {
          margin-top: 40px;
        }
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 992px) {
  .container {
    max-width: 881px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 900px) {
  .container {
    max-width: 881px !important;
  }
}
@media screen and (max-width: 900px)  {
  .heroSection {
    padding-top: 97px !important;
}
}
