.promptDiv {
  border-radius: 10px;
  border: 1px solid gainsboro;
  max-width: 98%;
  position: fixed;
  width: calc(100% - 30px); /* Full width minus padding */
  bottom: 20px;
  left: 0;
  right: 0;

  background-color: #fff;
  z-index: 999;
  margin: 0 2px;
}

@media screen and (min-width: 1440px) {
  .promptDiv {
    // width: calc(100% - 300px); /* Account for sidebar on larger screens */
    // left: 300px; /* Move the input box to the right of the sidebar */
    // right: 40px;
    // bottom: 0px;
  }
}

@media screen and (max-width: 1025px) {
  .promptDiv {
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

.selectedFileName {
  position: absolute;
  top: -40px;
  left: 10px;
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 10px;
  color: #333;
  
  max-width: 100%; 
  overflow-x: auto;
}

.promptArea1 {
  width: 5%;
  // border-right: 1px solid gainsboro;
  padding: 12px 18px;
}

.promptArea2 {
  width: 100%;
  padding:0px 18px;
  color: gray;
  input {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.promptArea3 {
  width: 5%;
  // border-left: 1px solid gainsboro;
  padding: 12px 18px;
}

.chatWithBot {
  overflow-y: scroll;
  height: 77vh;
  &::-webkit-scrollbar {
    display: none;
  }

  .chatMessageContainer {
  }
}

/* Modal Styling */
:where(.css-dev-only-do-not-override-98ntnt).ant-modal-root .ant-modal-wrap {
  position: fixed;
  inset: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  backdrop-filter: blur(10px);
  -webkit-overflow-scrolling: touch;
}

/* Chatbot Wrapper */
.chatbot-wrap {
  position: fixed;
  bottom: 95px;
  right: 60px;
}

.chatbot-wrap .chatbot-meassge-box {
  min-height: auto;
  position: relative;
}

.chatbot-wrap .chatbot-meassge-box .chatDiv {
  position: absolute;
  width: 316px;
  height: 460px;
  border-radius: 10px;
  background: #f9fafc;
  box-shadow: 0px 4px 15.7px 0px rgba(0, 0, 0, 0.18);
  bottom: 80px;
  right: 22px;
}

.chatbot-wrap .chatbot-meassge-box .chatDiv .header {
  height: 70px;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(92deg, #009eff 0.8%, #9360ff 98.13%);
  padding: 10px 30px;
}

.chatbot-wrap .chatbot-meassge-box .chatDiv .innerContentWrapper {
  padding: 41px 13px 11px 17px;
  height: 100%;
  max-height: 390px;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .parentChatBox {
  display: flex;
  flex-direction: column;
  gap: 23px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .parentChatBox
  .senderMsg {
  background: white;
  border: 1px solid gainsboro;
  padding: 5px;
  max-width: 90%;
  margin-left: auto;
  border-radius: 4px;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .parentChatBox
  .senderMsg
  p {
  font-size: 12px;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .parentChatBox
  .receiverMsg {
  max-width: 90%;
  border-radius: 4px;
  background: var(--primary);
  color: white;
  padding: 11px 5px 8px 5px;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .parentChatBox
  .receiverMsg
  p {
  font-size: 13px;
  line-height: 17px;
}

.chatbot-wrap .chatbot-meassge-box .chatDiv .innerContentWrapper .msgTextField {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 1px 5.5px 0px rgba(0, 0, 0, 0.12);
  height: 40px;
  position: relative;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .msgTextField
  .msgSendIcon {
  position: absolute;
  top: 3px;
  right: 4px;
}

.chatbot-wrap
  .chatbot-meassge-box
  .chatDiv
  .innerContentWrapper
  .msgTextField
  input {
  padding: 11px 48px 10px 23px;
  border-radius: 30px;
}

/* Media Queries */
@media screen and (max-width: 1440px) {
  // .promptDiv {
  //   max-width: 98%;
  //   left: 300px;
  //   right: 40px;
  //   bottom: 0px;
  // }

  .chatbot-wrap {
    bottom: 85px;
    right: 35px;
  }
}

@media screen and (max-width: 1025px) {
  .promptDiv {
    // max-width: 100%;
    // margin: 0 auto;
    // left: 0;
    // right: 0;
    // bottom: 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .promptDiv .promptArea1,
  .promptDiv .promptArea3 {
    padding: 15px 22px;
  }

  .chatbot-wrap {
    bottom: 83px;
    right: 15px;
  }

  .chatbot-wrap .chatbot-meassge-box img {
    width: 40px;
  }

  .chatbot-wrap .chatbot-meassge-box .chatDiv {
    bottom: 55px;
    right: 7px;
  }
}
