.rightArea{
    
    .editDiv{
        // width: fit-content;
    }
}

@media screen and (max-width:1000px) {
    .rightArea{
        .editDiv{
            position: absolute;
            right: 30px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 1px 7.8px 0px rgba(0, 0, 0, 0.1);
            padding: 10px;
            height: fit-content;
            z-index: 8;
            align-items: baseline;
        }
    }
    
}