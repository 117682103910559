.footerSection {
  padding-bottom: 30px;
  .container {
    .inner {
      border-top: 2px solid var(--primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 88px 10px;
      gap: 15px;
      .leftArea {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rightArea {
        width: 54%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        gap: 17px;
        p {
          text-align: right;
          max-width: 400px;
          width: 100%;
        }
        .inputDiv {
          width: 70%;

          text-transform: lowercase;
          .input-fieldd {
            &::placeholder {
            }
          }
        }
      }
    }

    .bottomSection {
      border-top: 1px solid gainsboro;
      padding-top: 24px;
      display: flex;

      align-items: center;
      justify-content: space-between;
      .leftBtmSection {
        width: 40%;

        ul {
          justify-content: space-between;
        }
      }

      .rightBtmSection {
        width: 70%;
        justify-content: space-between;
        display: flex;
        opacity: 0.5;
        .policy-link {
          color: #0a142f;
        }
        p {
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .footerSection {
    .container {
      .bottomSection {
        .leftBtmSection {
          ul {
            justify-content: space-between;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .footerSection {
    .container {
      .bottomSection {
        .leftBtmSection {
          ul {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 900px) {
  .footerSection {
    .container {
      .inner {
        .rightArea {
          width: 86%;
          .inputDiv {
            width: 88%;
          }
        }
      }
    }

    .input-wrapper {
      input {
        padding: 0 8px !important;
      }
      & ::placeholder {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footerSection {
    padding: 10px;
    .container {
      .inner {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px;
        padding-top: 45px;
        .leftArea {
          width: 100%;
          img {
            width: 60px;
          }
        }
        .rightArea {
          width: 100%;
          p {
            text-align: center;
            width: 100%;
            max-width: 499px;
          }
          .inputDiv {
            width: 100% !important;

            .ftrEmail,
            .input-wrapper {
              width: 100% !important;
            }
          }
        }
      }
      .bottomSection {
        flex-direction: column;
        .leftBtmSection {
          width: 100%;
          ul {
            flex-direction: column;
            font-weight: 600;

            font-size: 13px !important;
            li {
              font-size: 13px;
            }
          }
        }
        .rightBtmSection {
          width: 100%;
          justify-content: center;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
