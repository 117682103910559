
html {
    scroll-behavior: smooth;
  }
.h-center{
    display: flex;
    justify-content: center;
}
.v-center{
    display: flex;
    align-items: center;
}

.text-underline {
    text-decoration: underline;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  a.text-decoration-underline {
    text-underline-offset: 3px; // Adjust the offset value as needed
  }
  .topGradLayer{
    background-image: linear-gradient(180deg, #fff, #ffffff);
    border-radius: 20px;
    filter: blur(45px);
    height: 40px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index:5;
  }

  // .blur-background {
  //   backdrop-filter: blur(100px);
  //   -webkit-backdrop-filter: blur(50px); /* For Safari */
  // }


  