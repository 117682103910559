
  
  .dashnavbar-container {
    width: calc(100% - 320px);
    padding: 13px;
    border-radius: 41px 0px 0px 41px;
    background: #fff;
    justify-content: right;
    box-shadow: 0px 0px 10px #2b2b2b10;
  
    .dashnav-left {
      h5 {
        color: #2b2b2b;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  
    .dashnav-right {
      gap: 24px;
  
      .nav-search {
        border-radius: 41px;
        border: 1px solid #dcdcdd;
        background: #f2f2f2;
        padding: 10px;
  
        input {
          background: transparent;
          border: none;
        }
      }
  
      .trophy-div {
        margin-left: 16px;
        gap: 10px;
  
        span {
          padding: 5px 18px;
          border-radius: 41px;
          border: 1px solid #fed843;
        }
      }
  
      .noti-icon {
        width: 24px;
        height: 24px;
      }
  
    }
  }

  @media screen and (max-width:1024px) {
    .dashnavbar-container{
        display: block !important;
    }
    .Dashboard-main-container{
      .dashboardMenu{
        top: 55px;
    right: 48px;
    position: absolute;
    z-index: 10;

      }
    }
    
  }
  
 
  @media screen and (max-width: 697px) {

    .Dashboard-main-container{
      .dashboardMenu{
         top: 34px;
        right: 26px;
        position: absolute;
        z-index: 10;


      }
    }
    
  }
  @media (min-width: 697px) and (max-width: 697px) {
    .Dashboard-main-container{
      .dashboardMenu{
         top: 52px;
       

      }
    }
  }
  
//   @media only screen and (max-height: 800px) {
//     .dashnavbar-container {
//       padding: 15px 24px;
//       width: calc(100% - 290px);
  
//       .dashnav-right {
//         .nav-search {
//           padding: 8px;
//         }
//       }
//     }
//   }
  
//   @media only screen and (max-width: 1023px) {
//     .dashnavbar-container {
//     //   justify-content: space-between;
//     //   width: 95%;
//       display: block;
  
//       .dashnav-left {
//         // display: block !important;
//       }
//     }
//   }
  
//   @media only screen and (max-width: 700px) {
//     .dashnavbar-container {
//       padding: 10px 15px;
//       margin-left: 10px;
  
//       .dashnav-right {
//         gap: 3px;
  
//         .nav-search {
//           padding: 5px;
  
//           img {
//             width: 12px;
//             height: 12px;
//           }
  
//           input {
//             width: 70px;
//             font-size: 12px;
//           }
//         }
  
//         .trophy-div {
//           margin-left: 6px;
//           gap: 6px;
  
//           span {
//             padding: 5px 14px;
//             font-size: 12px;
//           }
  
//           .trophy-icon {
//             width: 18px;
//             height: 18px;
//             margin-top: 3px;
//           }
//         }
  
//         .noti-icon {
//           width: 22px;
//           height: 24px;
//         }
  
//         .nav-user-profile {
//           span {
//             display: none;
//           }
//         }
//       }
//     }
//   }