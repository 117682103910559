.faqSection {
  position: relative;
  margin-bottom: 243px;
  padding-top: 40px;

  .blueGlow {
    top: -100px;
    right: 0px;
    position: absolute;
    z-index: -1;
  }
  .purpleGlow {
    top: 200px;
    left: 0px;
    position: absolute;
    z-index: -1;
  }
  .container {
    .faq-container {
      width: 80%;
      margin-top: 60px !important;
      margin: 0 auto;

      .accordianHeader {
        font-size: 25px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 127.273% */
        border: none !important;
      }

      .accordion-item {
        position: relative;
        margin-bottom: 20px;
        border-radius: 18px;
        background: #fff;
        border: 1px solid white;
        // overflow: hidden;
        box-shadow: 0px 23px 16px -21px rgba(0, 0, 0, 0.2) !important;
        &:after {
          position: absolute;
          content: "";
          width: 101%;
          // height: 115%;
          border-radius: 23px;
          background-image: linear-gradient(180deg, #0085ff, #c67dfa);
          top: -5px;
          left: -5px;
          bottom: -5px;
          right: -5px;
          z-index: -1;
          opacity: 0;
          transition: 0.7s ease-in-out;
        }

        &:hover {
          // border: 2px solid pink;
          &::after {
            opacity: 1;
            transition: 0.7s ease-in-out;
          }
        }
      }
      .accordion-button {
        color: var(--Black, #090b1e);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Lato;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 22.4px */
        border: none;
        // box-shadow: 0px 23px 16px -21px rgba(0, 0, 0, 0.20) !important;
        display: flex;
        align-items: center;
        border-bottom: 0px !important;
        justify-content: center;
        border-radius: 18px;


        &:not(.collapsed) {
          color: #0085ff;
        }
        


        &:hover {
          // border: 2px solid pink;
        }
        &::after {
          margin-right: 5px;
          background-color: #ffffff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          content: url("../../../assets/icons/plus.png");
          transform: rotate(0deg);
          transition: all 0.7s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          // padding-top: 9px;
          // padding-left: 1px;
          background-image: none;
          // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
      }

      // .accordion-item:first-of-type > .accordion-header .accordion-button {
      //   border-radius: 7px;
      //   background: #FFF;
      //   box-shadow: 0px 23px 16px -21px rgba(255, 255, 255, 0.2);
      //   border: none !important;
      //   border-bottom: 1px solid gainsboro;
      // }

      .accordion-item:last-of-type
        > .accordion-header
        .accordion-button.collapsed {
        border-bottom-right-radius: 22px;
        border-bottom-left-radius: 22px;
      }
      .accordion-button:not(.collapsed) {
        background: white;
        border-bottom: none !important;
        box-shadow: none;
      }
      .accordion-button:not(.collapsed)::after {
        // transform: rotate(180deg) !important;
        content: url("../../../assets/icons/minus.png");

        // padding-bottom: 15px;
        // padding-right: 4px;
        border: none;
      }

      .accordion-button:focus {
        z-index: 3;
        border-color: none;
        outline: none;
        box-shadow: none;
      }
      .accordion-body {
        color: var(--Black, #090b1e);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */

        padding: 10px 20px 30px 19px;
        box-shadow: 0px 23px 16px -21px rgba(0, 0, 0, 0.2);
      }
    }
  }
}


@media screen and (min-width: 1400px){
  .accordion-item {
    &:after {
      width: 101.15% !important;
      left: -6px !important;
    }

   
  }

}

@media screen and (max-width: 1400px) {
  .faqSection {
    margin-bottom: 100px;
    .blueGlow {
      display: none;
    }
    .purpleGlow {
      display: none;
    }
    .container {
      .faq-container {
        margin-top: 100px !important;
        width: 100%;
      }
    }
  }
}
@media (width: 1400px) {
  .accordion-item {
    &:after {
      width: 101% !important;
      left: -6px !important;
    }

   
  }

}
@media screen and (max-width: 1200px) {
  .accordion-item {
    &:after {
      width: 101% !important;
      left: -4px !important;
    }

   
  }
}


@media screen and (max-width: 992px) {
  .accordion-item {
    &:after {
      width: 101.25% !important;
      left: -5px !important;
    }

   
  }
}
@media screen and (max-width: 868px) {
  .accordion-item {
    &:after {
      width: 101.15% !important;
    }

   
  }
}
@media screen and (max-width: 852px) {
  .accordion-item {
    &:after {
      width: 101.20% !important;
    }

   
  }
}


@media screen and (max-width: 768px) {
  .accordion-item {
    &:after {
      width: 101.20% !important;
      left: -4px !important;
    }

   
  }

}
@media screen and (max-width: 700px) {
  .accordion-item {
    &:after {
      width: 101.25% !important;
      left: -4px !important;
    }

   
  }

}
@media screen and (max-width: 627px) {
  .accordion-item {
    &:after {
      width: 101.50% !important;
    }

   
  }

}
@media screen and (max-width: 525px) {
  .accordion-item {
    &:after {
      width: 101.60% !important;
    }

   
  }

}
@media screen and (max-width: 494px) {
  .accordion-item {
    &:after {
      width: 101.75% !important;
    }

   
  }

}
@media screen and (max-width: 455px) {
  .accordion-item {
    &:after {
      width: 101.95% !important;
    }

   
  }

}
@media screen and (max-width: 405px) {
  .accordion-item {
    &:after {
      width: 102% !important;
      left:-5px !important;
    }

   
  }

}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .accordianHeader {
    b:nth-last-child(-n + 3) {
      display: flex !important;
      align-self: start !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .faqSection {
    margin-bottom: 50px;
    .container {
      .faq-container {
        margin-top: 40px !important;
        .accordion-button {
          align-items: flex-start !important;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .accordion-item {
    &:after {
      width: 103% !important;
    }

   
  }

}
@media screen and (max-width: 298px) {
  .accordion-item {
    &:after {
      width: 104% !important;
    }

   
  }

}
