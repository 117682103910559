.profileContent {
  padding: 32px 95px 31px 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 39px;
  justify-content: left;
  .save-profile{
    width: 80%;
  }
  .billingDiv {
  }
  form {
    width: 100%;
    .nameDiv {
      gap: 73px;
      .fnameContainer {
        width: 400px;
        

        .ftrEmail {
          max-width: 100%;
          width: 100%;
          height: 46px;
        }
      }
      
    }
    .saveProfileBtn {
      height: 93px;
    }
    .error-message {
      color: red;
      font-size: 13px;

      font-weight: 500;
    }
  }
}
.about-div{
  width: 870px !important;
}
.swal2-close{
  display: block  !important;
  color: #000 !important;
  font-size: 32px !important;
}



@media screen and (max-width: 1440px) {
  .profileContent {
    padding: 0px;
    padding-left: 20px;
    .billingDiv {
      p {
        font-size: 14px;
      }
    }
    form {
      .nameDiv {
        width: 80% !important;
        .role-input{
          width: 320px !important;
         }
        .fnameContainer {
          // width: 300px;
          label {
            font-size: 14px;
          }
        }
      }
    }
  }
  .about-div{
    width: 720px !important;
  }
}
@media screen and (max-width: 1220px) {
  .profileContent {
    .billingDiv {
    }
    form {
      .nameDiv {
        width: 70% !important;
        .role-input{
          width: 270px !important;
         }
      
      }
    }
  }
  .about-div{
    width: 620px !important;
  }
 

}
@media screen and (max-width: 1158px){
  .about-div{
    width: 520px !important;
  }

}
@media screen and (max-width: 850px) {
  .profileContent {
    form {
      max-width: 400px;
      width: 100%;
      // margin: 0 auto;
      .nameDiv {
        gap: 20px;
        flex-direction: column;
        .role-input{
          width: 400px !important;
         }
      }
    }
    .about-div{
      width: 410px !important;
    }
  }
}
@media screen and (max-width: 768px){
  .profileContent {
    .billingDiv {
    }
    form {
      .nameDiv {
        .role-input{
          width: 397px !important;
         }
       
      }
    }
    .about-div{
      width: 100% !important;

    }
  }


}
@media screen and (max-width: 530px){
  .profileContent {
    form {
      .nameDiv {
        width: 100% !important;
        .role-input{
          max-width: 350px !important;
          width: 100%;
         }
        .fnameContainer {
          max-width: 356px;
          width: 100%;
          label {
            font-size: 14px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 474px){
  .profileContent {
    form {
      .nameDiv {
        width: 100% !important;
        .role-input{
          width: 100% !important;
         }
        .fnameContainer {
          width: 100% !important;
        }
      }
    }
  }

}
