.chat-rating {
    display: flex;
    gap: 6px; // Slightly tighter spacing
    margin-top: 12px;
  
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 6px;
      border: 1px solid transparent;
      background: #f3f4f6; // Light gray background
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      svg {
        width: 16px;
        height: 16px;
        fill: none; // No fill by default
        stroke: #6b7280; // Default gray outline
        stroke-width: 2;
        transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
      }
  
      &:hover {
        background: #e5e7eb; // Slightly darker gray on hover
      }
  
      &.active-up {
        background: #e5e7eb; // Light green background
        svg {
          fill: #948e8e; 
          stroke: #948e8e; 
        }
      }
  
      &.active-down {
        background: #e5e7eb;
        svg {
            fill: #948e8e; 
            stroke: #948e8e;  
        }
      }
    }
  
    // Inactive state resets fill and stroke to gray
    &.inactive {
      button {
        background: #f3f4f6;
        svg {
          fill: none;
          stroke: #6b7280; // Gray outline when inactive
        }
      }
    }
  }
  
  .chat-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  
    .feedback-message {
      margin-left: 10px;
      font-size: 14px;
      color:#0B5ED7; // Green color for success message
      font-weight: 500;
      transition: opacity 0.3s ease-in-out;
    }
  }
  