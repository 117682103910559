.chat {
  min-height: 90vh;
  height: 100%;
  position: relative;
  h3 {
    color: var(--Dull, rgba(9, 11, 30, 0.63));
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
  }
  .chat-data {
    margin-top: 4rem;
    // margin-right: 11px;
    .chattname {
      display: flex;
      align-items: flex-start;
      gap: 4rem;

      .chatting {
        margin-bottom: 2rem;
        max-width: 100%;
        width: 100%;

        h4 {
          color: var(--Black, #090b1e);
          font-size: 16px;
          font-weight: 600;
        }
        h5 {
          color: #363c4f;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        li {
          color: #363c4f;
          text-align: justify;
          margin-bottom: 5px;
          font-size: 16px;
          list-style-type: disc;
          margin-left: 30px;
        }
        p {
          color: #363c4f;
          text-align: justify;
          margin-bottom: 10px;
          line-height: normal;
        }
        .icon-container {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          box-shadow: 3px 5px 5px -3px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .unclicked {
          color: #0085ff;
          background-color: white;
          transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        }
        .clicked {
          background-color: #0085ff;
          color: white;
          transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        }
      }
      .box {
        border-radius: 8px;
        border: 1px solid gainsboro;
        background: #fff;
        height: auto;
        width: auto;
        padding: 12px 10px;
        gap: 10px;
        align-items: flex-start;
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
    .docUpload {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .uploadboxwrap {
    width: 666px;
    height: auto;
    border-radius: 32px;
    background: #fff;
    box-shadow: 0px 4px 22.2px 0px rgba(0, 0, 0, 0.15);
    padding: 19px 33px 19px 33px;
    h4 {
      font-weight: 700;
    }
    .upload-box {
      border-radius: 10px;
      border: 1px dashed var(--Blue, #0085ff);
      padding: 33px;
      h5 {
        font-size: 22px;
        font-weight: 700;
      }
      p {
        font-weight: 600;
      }
      img {
        width: 84.597px;
        height: 81px;
      }
    }
  }
}
// .icon-container {
//   cursor: pointer;
//   padding: 5px;
//   border-radius: 50%;
// }

// .icon-container.clicked {
//   background-color: #007bff; /* or any color you want for the clicked state */
//   color: white;
// }

// .icon-container.unclicked {
//   background-color: transparent;
//   color: black;
// }
.kycWriting {
  padding: 14px 14px;
  position: fixed;
  z-index: 999;
  top: 0px;
  background-color: white;
  max-width: calc(100vw - 320px);
  width: 100%;
  margin-left: 0px;
}
.header {
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px; /* Adjust the size of the icon */
  color: white;    /* Change the color to match your design */
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .chat {
    // margin-bottom: 100px;
    .btn-primary {
      // width: 30% !important;
      margin: 0 0 !important;
    }
    .chat-data {
      margin-top: 0px;
      margin-right: 0px;
      .chattname {
        //margin-bottom: 70px;
        gap: 20px;
        .chatting {
          margin-bottom: 16px;
          h4 {
            font-size: 21px;
          }
          li {
            margin-bottom: 20px;
          }
          p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .chat .chat-data .docUpload {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 1025px) {
  .chat .chat-data {
    margin-top: 2rem;
    margin-right: 0;
  }
  .kycWriting {
    max-width: 100% !important;
    display: flex;
    justify-content: space-around !important;
    padding-right: 50px;
    z-index: 5 !important;
  }
  // .chatArea{
  //   padding: 20px;

  // }
}


@media screen and (max-width: 768px) {
  .chat {
    // padding: 10px;
    margin-bottom: 0px;
    padding-right: 10px;
    height: 100vh;
    margin-top: 100px;
   
    h3 {
      font-size: 20px;
      width: 90% !important;
      line-height: 25px !important;
      padding-bottom: 10px !important;
    }
    .btn-primary {
      // width: 50% !important;
      align-self: start;
    }
    .chat-data {
      .chattname {
        gap: 1rem;
        // img{
        //   width: 50px;
        // }
        .userIcon {
          width: 55px;
        }
        .pageIcon {
          display: none;
        }
        .chatting {
          margin-bottom: 0px;
          p {
            text-align: left;
          }
        }
      }
    }
  }
  .kycWriting {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 10px 10px;
    max-width: 100% !important;
    justify-content: space-between !important;
    //gap: 10px;
  }
  .chatting{
    padding: 10px;

  }
}
@media screen and (max-width: 500px){
  .chat {

    .kycWriting {
      max-width: 100% !important;
    }

}
}
