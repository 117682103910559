.btn {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  gap: 10px;
  border-radius: 5px;

  border: none;
}

.btn-primary {
  background: var(--primary);

  color: var(--secondary);
  // &::after {
  //   position: absolute;
  //   bottom: -10%;
  //   left: 0;
  //   content: "";
  //   border-radius: 5px;
  //   opacity: 0.67;
  //   background: #3156da;
  // //   filter: blur(15.382046699523926px);
  //   width: 100%;
  //   height: 39px;
  //   z-index: -1;
  // }
}
.btn-secondary {
  background: white;
  color: var(--field-text);
}
// .btn-secondary:hover {
//   color: var(--gray-text);
//   // border: 2px solid #2b2b2b !important;
//   background: transparent;
// }
.btn-grey {
  border-radius: 10px;
  border: 1px solid #ddd;
  //box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.32) inset;
}

// loading state
button {
  .loader {
    font-weight: bold;
    font-family: monospace;
    line-height: 1.2em;
    display: inline-grid;
    font-size: unset;
  }
  .loader:before,
  .loader:after {
    content: "Loading...";
    grid-area: 1/1;
    -webkit-mask: linear-gradient(90deg, #fff 50%, #fff 0) 0 50%/2ch 100%;
    color: #fff;
    text-shadow: 0 0 0 #fff, 0 calc(var(--s, 1) * 1.2em) 0 #fff;
    animation: l15 1s infinite;
  }
  .loader:after {
    -webkit-mask-position: 1ch 50%;
    --s: -1;
  }
  @keyframes l15 {
    80%,
    100% {
      text-shadow: 0 calc(var(--s, 1) * -1.2em) 0 #fff, 0 0 0 #fff;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}

.btn-connect {
  border-radius: 10px;
  background: var(--grad, linear-gradient(133deg, #3156da 2.91%, #7b98ff 100%));
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15) inset;
  color: #fff;
}
// @media only screen and (max-width: 1600px) {
//   .btn {
//     font-size: 16px !important;
//   }
//   .btn-primary {
//     min-width: 60px !important;
//     min-height: 39px !important;
//   }
//   .btn-secondary {
//     min-width: 50px !important;
//     min-height: 39px !important;
//   }

// }
@media only screen and (max-width: 1400px) {
  .btn {
    font-size: 16px !important;
  }
  // .btn-primary {
  //   min-width: 60px;
  //   min-height: 39px;

  //   align-self: center;

  // }
  .btn-secondary {
    min-width: 100px !important;
    min-height: 45px !important;
  }
  .btn-connect {
    min-height: 50px !important;
    min-width: 100px !important;
  }
}

@media only screen and (max-width: 600px) {
  .btn {
    font-size: 20px;
  }
}
