// .custom-feedback-modal {
//     .ant-modal-content {
//       border-radius: 12px;
//       padding: 20px;
//     }
  
//     .ant-modal-header {
//       border-bottom: none;
//     }
  
//     .ant-modal-title {
//       font-size: 18px;
//       font-weight: bold;
//     }
  
//     .modal-subtitle {
//       color: #666;
//       font-size: 14px;
//       margin-bottom: 10px;
//     }
  
//     .checkbox-group {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 8px;
      
//       .ant-checkbox-wrapper {
//         border: 1px solid #ddd;
//         padding: 6px 12px;
//         border-radius: 20px;
//         font-size: 14px;
//         transition: all 0.3s;
//         cursor: pointer;
  
//         &:hover {
//           background-color: #f5f5f5;
//         }
  
//         .ant-checkbox {
//           display: none;
//         }
//       }
  
//       .ant-checkbox-wrapper-checked {
//         background-color: #ede9fe;
//         border-color: #7b61ff;
//         color: #7b61ff;
//       }
//     }
  
//     .feedback-textarea {
//       margin-top: 12px;
//       border-radius: 8px;
//       padding: 10px;
//     }
  
//     .cancel-btn {
//       background-color: #f5f5f5;
//       color: #333;
//       border-radius: 6px;
  
//       &:hover {
//         background-color: #e0e0e0;
//       }
//     }
  
//     .submit-btn {
//       background-color: #0B5ED7;
//       color: #fff;
//       border-radius: 6px;
//       margin-top: 12px;
      
      
//     }
//   }
  

.custom-feedback-modal {
    .ant-modal-content {
      border-radius: 12px;
      padding: 20px;
    }
  
    .ant-modal-header {
      border-bottom: none;
    }
  
    .ant-modal-title {
      font-size: 18px;
      font-weight: bold;
    }
  
    .modal-subtitle {
      color: #666;
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .checkbox-group {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
  
      .ant-checkbox-wrapper {
        border: 1px solid #ddd;
        padding: 6px 12px;
        border-radius: 20px;
        font-size: 14px;
        transition: all 0.3s;
        cursor: pointer;
  
        &:hover {
          background-color: #f5f5f5;
        }
  
        .ant-checkbox {
          display: none;
        }
      }
  
      .ant-checkbox-wrapper-checked {
        background-color: #ede9fe;
        border-color: #7b61ff;
        color: #7b61ff;
      }
    }
  
    .feedback-textarea {
      margin-top: 12px;
      border-radius: 8px;
      padding: 10px;
    }
  
    .cancel-btn {
      background-color: #f5f5f5;
      color: #333;
      border-radius: 6px;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  
    .submit-btn {
      background-color: #0B5ED7;
      color: #fff;
      border-radius: 6px;
      margin-top: 12px;
    }
  }
  
  // Prevent background blur when modal is open
  .ant-modal-mask {
    backdrop-filter: none !important;
    background: rgba(0, 0, 0, 0.5) !important; // Adjust transparency as needed
  }
  
  .ant-modal-wrap {
    backdrop-filter: none !important;
  }
  