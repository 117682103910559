.settingWrapper {
  padding: 32px 51px 27px 31px;
  height: 100vh;
  border-radius: 14px;
  height: 100%;
  background-color: white;
  .tabSection {
    border-top: 1px solid gainsboro;
    .tabInnerContent {
      width: 100%;
      border-radius: 14px;
      background: #fff;
      //border: 1px solid green;
      margin-top: 16px;

      .tabLeftContent {
        padding: 69px 20px 69px 0px;
        width: 200px;
        border-right: 1px solid gainsboro;
        gap: 35px;
        a {
          color: black;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          font-weight: 600;
          font-size: 14px;
          &.active {
            color: var(--primary);
            .iconColor {
              color: var(--primary) !important;
            }
          }
        }
        // .logout {
        //   span {
        //   }
        // }
      }
    }
  }
}
// .navlinks {
//   color: black;
//   text-decoration: none;
//   &.active{
//     .activeLinks{
//       color: var(--primary) !important;
//     }
//   }
// }

// .navlinks.active {
//   color: var(--primary);

// }

.navlinks.logout {
  color: red;
}
@media screen and (max-width: 1440px) {
  .settingWrapper .tabSection .tabInnerContent .tabLeftContent {
    padding: 20px;
    // width: auto;
  }
  .settingWrapper {
    padding: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .settingWrapper .tabSection .tabInnerContent .tabLeftContent {
    padding: 20px;
    width: auto;
  }
}

@media screen and (max-width: 850px) {
  .settingWrapper {
    padding: 20px;
    padding-left: 10px;

    .tabSection {
      .tabInnerContent {
        .tabLeftContent {
          a {
            span {
              display: none;
            }
          }
          .logout {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
