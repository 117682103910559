.Dashboard-main-container {
  color: #000000;
  min-height: 100vh;
  height: 100%;
  background-color: #f9fafc;
  position: relative;
  .left-content {
    padding: 15px 0px !important;
  }
  .right_content {
    padding: 15px 0px 0px 0px;
    width: calc(100vw - 242px);
    margin-left: 300px !important;
    background-color: #f9fafc;
    //background: rgb(73, 56, 56);
  }
  .dashboardMenu {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 9999;
  }
}
@media only screen and (max-width: 1700px) {
  .Dashboard-main-container {
    .right_content {
      width: calc(100vw - 250px) !important;
      margin-left: 300px !important;
    }
  }
}
@media only screen and (max-width: 1025px) {
  .Dashboard-main-container {
    // margin-bottom: 20px !important;
    .right_content {
      width: 100% !important;
      margin-left: 0px !important;
      // padding: 20px;
    }
    .dashboardMenu {
      display: block !important;
      top: 30px !important;
      right: 30px !important;
      // z-index: 9999 !important;
      position: fixed !important;
      
      // top: 40px;
      // right: 36px;
      // z-index: 9999;
    }
  }
}
@media only screen and (max-width: 1000px){
  .chatArea {
    max-width: 100%;
    width: 100%;
    /* padding: 20px; */
    padding-left: 73px;
}
}
@media only screen and (max-width: 768px){
  .chatArea {
    padding-left: 20px;
}



}
@media only screen and (max-width: 800px){
  .dashboardMainContainer  {
    min-height: 90vh !important;
    .chatArea {
      // height: 53vh;
  }
    .promptDiv{
      width: 100%;
      // bottom: -242px !important;
      position: absolute !important;

    }
   
}
}

@media only screen and (max-width: 696px) {
  .Dashboard-main-container {
    .right_content {
      width: 100% !important;
      // margin-left: 10px !important;
      padding: 0px !important;
      min-height: 100vh;
    }

    .dashboardMenu {

      right: 9px !important;
    }
    
  }
}
