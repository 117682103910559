.chatArea {
  padding-bottom: 20px;
  overflow-y: auto;
  scrollbar-width: none;
  max-width: 65%;
  width: 100%;
  padding-top: 100px;
  margin: 0 auto;
}

.chatArea::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1440px) {
  .chatArea {
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    padding-bottom: 0px !important;
  }

  .chatArea::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .chatArea {
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width:800px){
  
}
@media only screen and (max-width: 768px) {
  .chatArea {
    padding-bottom: 0px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .chatArea::-webkit-scrollbar {
    display: none;
  }

  .promptDiv {
    margin-top: 5px;
  }
}

.promptDiv {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.promptArea1,
.promptArea3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.promptArea2 {
  flex-grow: 1;
}

.text-med {
  font-size: 16px;
  font-weight: normal;
  width: 100%;
}

.chatMessageContainer {
  display: block;
  margin-bottom: 20px;
  position: relative;
  width: auto;
  padding-bottom: 10px;
}
.userInitials {
  background-color: #333;
  color: white;
  padding: 6px 10px;
  border-radius: 40px;
  display: flex;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 5px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 0px;
}

.userMessage .chatBubble {
  width: auto;
  max-width: 90%;
  background-color: #f0f0f0;
  color: #333;
  font-weight: normal;
  padding: 10px;
  border-radius: 20px;
  margin-left: 55px;
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
}

.botMessage img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 2px;
}

.botMessageLogo {
  width: 50px !important;
  /* height: 40px; */
  display: inline-block;
  margin-top: 10px;
  vertical-align: center;
  /* margin-right: 10px; */
}

.botChatBubble {
  background-color: none;
  color: #555;
  border-radius: 20px;
  padding: 10px;
  width: auto;
  max-width: 90%;
  font-weight: normal;
  position: relative;
  display: inline-block;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  margin-bottom: 30px;
}

.botResponse {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  gap: 5px;
  position: relative;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

@media screen and (max-width: 768px) {
  .chatBubble {
    max-width: 90%;
    padding: 8px 12px;
  }
  .botResponse {
    padding-top: 20px !important;
  }
}

.upload-progress-div {
  margin-top: 20px;
  width: 100%;
}

.selectedFileName p {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.file-upload-modal .uploadboxwrap {
  padding: 20px;
}

.file-upload-modal h4 {
  text-align: center;
}

.upload-file-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 3px dashed #0085ff;
  border-radius: 10px;
  padding: 26px;
}

.upload-file-div img {
  width: 50px;
  height: 50px;
}

.upload-file-div h5 {
  margin-top: 10px;
  font-weight: bold;
}

.upload-file-div p {
  margin-top: 5px;
  color: #666;
}

.btn-primary {
  background-color: #0085ff;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.custom-confirmation-modal .ant-modal-content {
  border-radius: 20px;
  padding: 20px;
}

.custom-confirmation-modal .ant-modal-close {
  font-size: 18px;
}

.custom-confirmation-modal .btn {
  min-width: 100px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.custom-confirmation-modal .btn-primary {
  background-color: #007bff;
  color: white;
}

.custom-confirmation-modal .btn-secondary {
  background-color: #6c757d;
  color: white;
}

 textarea {
  width: 100%;
  min-height: 40px;
  padding: 12px;
  border-radius: 8px;
  resize: none;
  font-size: 16px;
  transition: background 0.2s, border-color 0.2s;
}

textarea:focus {
  outline: none;
}
