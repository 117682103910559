.loginWrapper {
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  .mainContainer {
    display: flex;
    min-height: 100vh;
    height: 100%;
    .leftArea {
      width: 30%;
      position: relative;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .rightArea {
      width: 70%;

      .midBox {
        max-width: 360px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-direction: column;
        form {
          .inputFieldContainer {
            width: 100%;
            gap: 19px;

            .error-message {
              color: red;
              font-size: 13px;
              margin-top: -10px;
              font-weight: 500;
            }
            .emailBox {
              width: 100%;
              .ftrEmail {
                width: 100%;
                border: 1px solid gainsboro;
                background: white !important;
              }
            }
            .pass-code {
              .input-element {
                border: 1px solid gainsboro;
                width: 51px !important ;
                height: 56px;
                border-radius: 10px;
                stroke-width: 1px;
                stroke: var(--Blue, #0085ff);
                &:focus-within {
                  border: 2px solid var(--primary);
                }
              }
            }
          }
          .nextBtn {
          }
        }

        .bottomSection {
          .orLoginText {
            .grayLine {
              width: 47px;
              height: 1px;
              background: rgba(96, 96, 96, 0.29);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .loginWrapper {
    height: auto;
    .mainContainer {
      padding-top: 0px;
      padding-bottom: 0px;
      .rightArea {
        height: auto;

        .midBox {
          padding: 20px;
          form {
            .inputFieldContainer {
            }

            .nextBtn {
              min-width: 100% !important;
              width: 100% !important;
              max-width: 100% !important;
            }
          }
          .loginTitle {
            text-align: center;
            h3 {
              font-size: 28px;
            }
          }
        }
      }
      .leftArea {
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .loginWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .mainContainer {
      width: 100%;
      flex-direction: column;
      .leftArea {
        display: none;
        img {
          display: none;
        }
      }
      .rightArea {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        .midBox {
          max-width: 400px;
          width: 100%;
          margin: 0 auto;
          padding: 20px;
          // height: 100vh;
          form {
            .loginTitle {
              text-align: center;
              h3 {
                font-size: 24px;
              }
              p {
                font-size: 15px;
              }
            }
            .inputFieldContainer {
            }
            .nextBtn {
              width: 100%;
              min-width: 100% !important;
            }
          }
        }
      }
    }
  }
}
