.dashboardMainContainer {
  // padding: 43px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 90vh;

  .welcomekycDiv {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;

    // margin: 0 auto;
    // border: 1px solid gainsboro;

    .customAMLDiv {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 27px;
      .card1 {
        min-height: 96px;
        width: 330px;
        padding: 26px 14px;
        border-radius: 8px;
        border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
        background: #fff;
        // gap: 20px;
        p {
          max-width: 231px;
          //  border: 1px solid red;
        }
      }
    }
  }
  .promptDiv {
    width: 100%;
    border: 1px solid gainsboro;
    position: relative;
    bottom: -10px;
    left: 0px;
  }
  .upgrade-content {
    //  padding: 42px 19px 27px 42px;
    border-radius: 32px;
    .upgrade-text {
      font-size: 12px;
    }

    .upgrade-select {
      border-radius: 3px;
      border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
    }
  }
}
:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-close {
  position: absolute;
  // top: 25px !important;
  right: 30px;
  color: black;
}

@media screen and (max-width: 1440px) {
  .dashboardMainContainer {
    .welcomekycDiv {
      // top: 39%;
      h2 {
        font-size: 30px;
      }

      .customAMLDiv {
        .card1 {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .dashboardMainContainer {
    .welcomekycDiv {
      //  top: 73%;
      top: 45%;
      text-align: center;
      h2 {
        font-size: 25px;
      }
      .customAMLDiv {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px !important;
        .card1 {
          width: 300px;
          margin: 0 auto;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-height: 800px) and (max-width: 800px) {
  .dashboardMainContainer {
    .welcomekycDiv {
      top: 75%;
    }
  }
}
@media screen and  (max-width: 992px) {
  .dashboardMainContainer {
    .promptDiv {
      width: 75%;
    }
  }
}
@media screen and  (max-width: 768px) {
  .dashboardMainContainer {
    .promptDiv {
      width: 92%;
    }
  }
}

