.sidebar-main-container {
  width: 300px;
  position: fixed;
  // border-radius: 0px 20px 20px 0px;
  background: #f9fafc;
  height: 100vh;
  border-right: 1px solid gainsboro;
  padding: 27px 17px 20px 17px;
  transition: width 0.3s ease-in-out;
  z-index: 40;

  .scrollable {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 2px;

    .newChatIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .btn.disabled {
      background-color: gray;
      cursor: not-allowed;
      pointer-events: none;
      img {
        background-color: gray;
      }
    }
    .summary {
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      padding-right: 10px;
    }

    .sidebar-logo-container {
      padding: 0px;
      position: relative;
      height: 50px;
      display: flex;
      justify-content: left;
      margin-bottom: 20px;

      .logo {
        //margin: auto;
        width: 115px;
      }
    }
    .pricePopup {
      font-size: 12px;
      .coloredDiv {
        width: 100%;
        height: 8px;
        border-radius: 5px;
        background: linear-gradient(92deg, #009eff 0.8%, #9360ff 98.13%);
      }
    }
    .sidebar-options-container {
      padding-top: 15px ;

      height: 100%;
      justify-content: space-between;
      .myChatBox {
        .todayClr {
          color: rgb(126, 122, 122);
        }
      }
      .mainList {
        .leftArea {
          .sideIconss {
            color: black;
          }
          a {
            color: black;
            &:hover {
              color: gray !important;
            }
            &.active {
              color: var(--primary) !important;
              .sideIconss {
                color: rgb(55, 90, 245) !important;
              }
              .accLinkColr {
                color: var(--primary) !important;
              }
            }
          }
        }
        a {
          color: black;
          &:hover {
            color: gray !important;
          }
          &.active {
            color: var(--primary) !important;
            .sideIconss {
              color: var(--primary) !important;
            }
          }
        }
        .rightArea {
          position: relative;
          .editDiv {
            position: absolute;
            background-color: white;
            top: 40px;
            right: 10px;
            z-index: 2;
            //border: 1px solid green;
            width:fit-content;
            height: fit-content;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 1px 7.8px 0px rgba(0, 0, 0, 0.1);
            padding: 10px;
            list-style: none;
            // opacity: 0;
            li {
              list-style: none;
            }
          }
        }
      }
      .accLinkColr {
        // border-top: 1px solid gainsboro;
        &.active {
          color: var(--primary) !important;
        }
      }
      // ul {
      //   height: 100%;

      //   li {
      //     .dashLinks {
      //       border-radius: 500px;
      //       padding: 15px 20px 15px 45px;
      //       cursor: pointer;
      //       color: black;
      //       font-size: 16px;
      //       font-weight: 400;
      //       margin-bottom: 20px;
      //       font-family: "Segoe UI";
      //       display: flex;
      //       align-items: center;

      //       .sidebar-icons {
      //         margin-right: 10px;
      //         color: black;
      //       }

      //       &.active {
      //         border-radius: 0px 8px 8px 0px;
      //         background: #f3f2fc;
      //         color: #2b2e48;
      //         font-weight: 700;
      //         letter-spacing: 0.16px;
      //         padding: 15px 20px 15px 45px;

      //         .sidebar-icons {
      //           color: #2b2e48;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(241, 241, 241, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 30px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #9858ff;
      }
    }
  }
  // .accLinkColr {
  //   color: black;

  //   .accSetting {
  //     button {

  //       &:hover {
  //         color: gray;
  //       }

  //       &:active {
  //         color: var(--primary) !important;
  //       }

  //       .accIconn {
  //         /* Styles for .accIconn */
  //       }

  //       .accText {
  //         /* Styles for .accText */
  //       }
  //     }
  //   }
  //   &.active{
  //     color: var(--primary) !important;
  //   }
  // }

  // .accLinkColr.active {
  //   color: blue !important;
  // }
  //  .accClass{
  //   color: black;
  //   &:hover{
  //     color:gray !important;
  //   }
  //   &.active{
  //     color: var(--primary) !important;
  //     .sideIconss{
  //       color: var(--primary) !important;
  //     }
  //   }
  //  }
}
.upgrade-content {
  //padding: 19px 41px 0px 42px;
}
.upgardeHeader {
  h4 {
    margin-top: 0px !important;
  }
}

// @media only screen and (max-height: 800px) {
//   .sidebar-main-container {
//     display: none;

//     .sidebar-logo-container {
//       padding: 12px 0px 12px 0px;
//     }

//     .sidebar-options-container {
//       ul {
//         li {
//           .dashLinks {
//             padding: 8px 20px 8px 45px;
//             font-size: 14px;

//             .sidebar-icons {
//               width: 20px;
//               height: 20px;
//             }

//             span {
//               margin-top: 0px;
//             }

//             &.active {
//               padding: 10px 20px 10px 45px;
//             }
//           }
//         }
//       }
//     }
//   }

//   .bottom-options {
//     margin-top: 0px !important;
//   }
// }

// @media only screen and (max-width: 1023px) {
//   .sidebar-main-container {
//     display: none !important;
//   }
//   .sidebar-container {
//     width: 260px;
//     position: fixed;
//     // border-radius: 0px 20px 20px 0px;
//     background: white;
//     height: 100vh;
//     border-right: 1px solid gainsboro;
//     padding: 27px 17px 60px 17px;

//     transition: width 0.3s ease-in-out;
//     z-index: 40;

//     .scrollable {
//       display: flex;
//       flex-direction: column;
//       overflow-y: auto;
//       height: 100%;
//       padding: 2px;

//       .newChatIcon {
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//       }

//       .sidebar-logo-container {
//         padding: 0px;
//         position: relative;
//         height: 50px;
//         display: flex;
//         justify-content: left;
//         margin-bottom: 20px;

//         .logo {
//           margin: auto;
//         }
//       }

//       .sidebar-options-container {
//         padding: 15px 0px;
//         height: auto;
//         .myChatBox {
//           .todayClr {
//             color: rgb(126, 122, 122);
//           }
//         }
//         .mainList {
//           .leftArea {
//           }
//           .rightArea {
//             position: relative;
//             . {
//               position: absolute;
//               background-color: white;
//               top: 40px;
//               right: 10px;
//               z-index: 2;
//               //border: 1px solid green;
//               width: 100px;
//               height: 80px;
//               border-radius: 4px;
//               background: #fff;
//               box-shadow: 0px 1px 7.8px 0px rgba(0, 0, 0, 0.1);
//               padding: 10px;
//               list-style: none;
//               // opacity: 0;
//               li {
//                 list-style: none;
//               }
//             }
//           }
//         }

//         // ul {
//         //   height: 100%;

//         //   li {
//         //     .dashLinks {
//         //       border-radius: 500px;
//         //       padding: 15px 20px 15px 45px;
//         //       cursor: pointer;
//         //       color: black;
//         //       font-size: 16px;
//         //       font-weight: 400;
//         //       margin-bottom: 20px;
//         //       font-family: "Segoe UI";
//         //       display: flex;
//         //       align-items: center;

//         //       .sidebar-icons {
//         //         margin-right: 10px;
//         //         color: black;
//         //       }

//         //       &.active {
//         //         border-radius: 0px 8px 8px 0px;
//         //         background: #f3f2fc;
//         //         color: #2b2e48;
//         //         font-weight: 700;
//         //         letter-spacing: 0.16px;
//         //         padding: 15px 20px 15px 45px;

//         //         .sidebar-icons {
//         //           color: #2b2e48;
//         //         }
//         //       }
//         //     }
//         //   }
//         // }
//       }

//       // &::-webkit-scrollbar {
//       //   width: 5px;
//       // }

//       // &::-webkit-scrollbar-track {
//       //   background: rgba(241, 241, 241, 0);
//       // }

//       // &::-webkit-scrollbar-thumb {
//       //   background: transparent;
//       //   border-radius: 30px;
//       // }

//       // &:hover {
//       //   &::-webkit-scrollbar-thumb {
//       //     background: #9858ff;
//       //   }
//       // }
//     }
//     .accSetting {
//       border-top: 1px solid gainsboro;
//       button {
//         background-color: white;
//       }
//     }
//   }
//   // .mobile-sidebar {
//   //   display: block !important;
//   // }
//   .mobile-menu-drawer {
//     background: none !important;
//     padding: 0;
//   }

//   :where(.css-dev-only-do-not-override-42nv3w).ant-drawer .ant-drawer-body,
//   .ant-drawer,
//   .ant-drawer-body {
//     padding: 0px !important;
//   }

//   :where(.css-dev-only-do-not-override-42nv3w).ant-drawer-left
//     > .ant-drawer-content-wrapper,
//   .ant-drawer-content-wrapper {
//     box-shadow: none !important;
//   }
// }

@media screen and (max-width: 1500px) {
  .sidebar-main-container {
    .scrollable {
      .sidebar-options-container {
        .logo {
          margin-top: 10px;
        }
        .myChatBox {
          .todayClr {
            font-size: 13px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
        }
        .mainList {
          .leftArea {
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  hr {
    margin: 6px;
  }
  .upgrade-content {
    padding: 0px;
  }
}
@media screen and (max-width: 1025px) {
  .sidebar-main-container {
    display: none !important;
  }
}

@media only screen and (max-height: 800px) {
  .sidebar-main-container {
    padding: 13px 17px 27px 17px;
    .ant-drawer-content-wrapper {
      width: 300px;
    }
  }
}
@media screen and (max-width: 800px) {
  .upgrade-content {
    .upgardeHeader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      align-items: center;
      h4 {
        text-align: center;
      }
      p {
        text-align: center;
      }

      .upgradePriceContent {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}
